/** PAGE LOAD **/
$(document).ready(function () {
// User clicks a region to show the countries
    $('.zone-name').on('click', function (event) {
        if ($(event.target).hasClass('showing')) {
            $('.zone-country[data-zone-id=' + event.target.dataset.zoneId + ']').addClass('hiding');
            $(event.target).removeClass('showing');
        } else {
            $('.zone-country[data-zone-id=' + event.target.dataset.zoneId + ']').removeClass('hiding');
            $(event.target).addClass('showing');
        }
    });
});